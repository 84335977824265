.Auth {
  min-width: 360px;
}

.Auth input {
  font-size: 15px;
  height: 36px;
  padding: 9px 27px; 
  border-radius: 9px;
  border: 1px solid #000;
  display: block;
  width: calc(100% - 54px);
  margin-bottom: 18px;
}

.Auth button {
  font-weight: 800;
  font-size: 15px;
  background-color: var(--button);
  color: var(--buttonText);
  cursor: pointer;
  border-radius: 9px;
  border: 1px solid #000;
  padding: 18px 27px; 
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .Auth {
    min-width: 0;
  }
}