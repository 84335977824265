.ProfileForm {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.ProfileForm .Progress {
  display: flex;
  flex-direction: row;
  gap: 9px;
  justify-content: center;
  margin-bottom: 36px;
}

.ProfileForm .Progress .Step {
  width: 93px;
  height: 9px;
  background-color: lightgray;
  cursor: pointer;
}

.ProfileForm .Progress .Active {
  background-color: darkgray;
}

.ProfileForm .Description {
  width: 297px;
  font-size: 18px;
  margin: 0 18px 18px 18px;
}

.ProfileForm h3 {
  text-align: center;
}

.ProfileForm .Inputs {
  display: flex;
  justify-content: center;
}

.ProfileForm .Interests {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin-bottom: 27px;
  width: 540px;
  max-height: 369px;
}

.ProfileForm input {
  font-size: 18px;
  font-weight: 700;
  width: 240px;
  height: 39px;
  padding: 9px 27px; 
  border-radius: 9px;
  border: 1px solid #000;
}

.ProfileForm button {
  font-weight: 800;
  font-size: 15px;
  background-color: var(--button);
  color: var(--buttonText);
  cursor: pointer;
  border-radius: 9px;
  border: 1px solid #000;
  padding: 18px 27px; 
  width: 297px;
}

.chip {
  display: inline-block;
  padding: 0px;
  margin: 3px;
  height: 36px;
  border-radius: 9px;
  background-color: #fff;
  margin-right: 10px;
  position: relative;
  border: 1px solid #000;
}

.chip input[type="checkbox"] {
  display: none;
}

.chip label {
  display: block;
  padding: 0 18px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.chip input[type="checkbox"]:checked + label {
  color: var(--buttonText);
  background-color: var(--button);
  border-radius: 9px;
}