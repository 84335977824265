.Navbar {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 45px;
  background-color:beige;
  opacity: 0.9;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Navbar li {
  list-style: none;
}

.Navbar li a {
  font-size: 15px;
  display: block;
  color:black;
  text-decoration:none;
  text-align:center;
}

.Navbar .BackBtn {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  gap: 9px;
  padding: 0 9px;
  margin-left: 9px;
}

.Navbar .BackBtn .BackBtnTab {
  display: flex;
}

.Logout {
  font-size: 15px;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.NavbarMenu {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding: 0 18px;
  align-items: center;
}

.Logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.Logo a {
  font-family: 'Quicksand';
  font-size: 18px;
  font-weight: 700;
  color:black;
}

.Profile {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 9px;
}

.ProfileName {
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
}

.ProfileAvatar {
  display: flex;
  align-items: center;
}

.ProfileAvatar img {
  border-radius: 54%;
}

.ProfileDropdownContent {
  position: absolute;
  right: 0;
  background-color: beige;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.ProfileDropdownContent {
  display: flex;
  flex-direction: row;
}

.ProfileDropdownTab {
  display: flex;
  flex-direction: column;
  padding: 18px 27px;
  width: 162px;
}

.AccountTab {
  width: 108px;
}

.ProfileDropdownTab h3 {
  font-size: 21px;
  margin: 9px 0 18px 0;
}

.ProfileDropdownTab a {
  text-align: left !important;
  font-size: 18px !important;
  margin-bottom: 15px;
}

.ProfileDropdownTab button {
  text-align: left;
  font-size: 18px;
  margin-bottom: 15px;
  color: black;
}

.ProfileDropdownTab .Profile {
  margin-bottom: 15px !important;
}

.ProfileDropdownTab .ProfileName {
  font-weight: 500;
} 

#TreasureButton {
  display: block;
}

#TreasureButtonMobile {
  display: none;
}

.TreasureButton {
  display: flex;
  flex-direction: row;
  margin-right: 18px;
}

.TreasureName {
  display: flex;
  align-items: center;
  margin-right: 9px;
  font-weight: 700;
}

.TreasureAvatar {
  display: flex;
}

.TreasureAvatar img {
  border-radius: 54%;
}

@media only screen and (max-width: 768px), ( (max-width: 999px) and (orientation: landscape) ) {
  #TreasureButton {
    display: none;
  }
  #TreasureButtonMobile {
    display: block;
  }
}