#SettingsBody {
  overflow-y: auto;
  height: calc(100vh - 45px);
  width: calc(100%);
  position: absolute;
  top: 45px;
  z-index: 2;
}

.SettingsContent {
  background-color: white;
  border-radius: 9px;
  border: 1px solid black;
  margin: 18px auto;
  display: flex;
  flex-direction: row;
  max-width: 972px;
  padding: 36px;
}

.SettingsContentTab {
  display: flex;
  width: 50%;
}

.SettingsContent h3 {
  text-decoration: underline;
}

.SettingsContent h4 {
  margin: 9px 0;
}

.SettingsContent a {
  padding: 9px 18px;
  border-radius: 9px;
  border: 1px solid black;
  color: black;
  text-align: center;
  max-width: 270px;
  background-color: white;
  font-size: 15px;
  cursor: pointer;
}

.Account input {
  border-radius: 9px;
  font-size: 15px;
  padding: 9px 18px;
  border: 1px solid black;
  margin-bottom: 9px;
}

.Account button {
  padding: 9px 18px;
  border-radius: 9px;
  border: 1px solid black;
  color: black;
  text-align: center;
  max-width: 270px;
  background-color: white;
  font-size: 15px;
  cursor: pointer;
  margin-right: 9px;
}

.SubscriptionPeriod {

}

.SubscriptionManage {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.SubscriptionManage .SubscriptionManageItem {
  display: flex;
  flex-direction: column;
}

.SubscriptionManage .SubscriptionManageItem a {
  padding: 9px 18px;
  border-radius: 9px;
  border: 1px solid black;
  color: black;
  font-size: 15px;
}

.SubscriptionManage .SubscriptionManageItem button {
  padding: 9px 18px;
  border-radius: 9px;
  border: 1px solid black;
  color: black;
  font-size: 15px;
  cursor: pointer;
  background-color: white;
}

.PaymentMethod {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}