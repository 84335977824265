.ManagePayment {

}

.ManagePayment .AddPayment {
  margin: 9px 0;
}

.ManagePayment .AddPayment button {
  background-color: white;
  border: 1px solid black;
  border-radius: 9px;
  padding: 6px 18px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  color: black;
  font-size: 15px;
}

.ManagePayment .PaymentList {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.ManagePayment .PaymentList .PaymentItem {
  display: flex;
  flex-direction: row;
  gap: 9px;
}

.ManagePayment .PaymentList .PaymentItem .Brand {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgb(228, 228, 228);
  color: black;
  background-color: lightgray;
  border-radius: 9px;
  padding: 3px 12px;
  font-size: 12px;
  min-width: 90px;
  text-align: center;
}

.ManagePayment .PaymentList .PaymentItem .Number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 126px;
}

.ManagePayment .PaymentList .PaymentItem .Exp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 108px;
}

.ManagePayment .PaymentList .PaymentItem .Actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.ManagePayment .PaymentList .PaymentItem .Actions .Default {
  display: flex;
  background-color: transparent;
  border: 0;
  color:blue;
  font-weight: 700;
}

.ManagePayment .PaymentList .PaymentItem .Actions .SetDefault {
  display: flex;
}

.ManagePayment .PaymentList .PaymentItem .Actions .SetDefault button {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 9px;
  padding: 3px 9px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  color: black;
}

.ManagePayment .PaymentList .PaymentItem .Actions .Remove {
  display: flex;
}

.ManagePayment .PaymentList .PaymentItem .Actions .Remove button{
  background-color: white;
  border: 1px solid black;
  border-radius: 9px;
  padding: 3px 9px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  color: black;
}