#ProgressBody {
  overflow-y: auto;
  height: calc(100vh - 45px);
  width: calc(100%);
  position: absolute;
  top: 45px;
  z-index: 2;
}

.ProgressContent {
  background-color: white;
  border-radius: 9px;
  border: 1px solid black;
  margin: 18px auto;
  display: flex;
  flex-direction: column;
  max-width: 1044px;
}

.ProgressHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 27px;
}

.ProgressHeader h3 {
  display: flex;
  flex-grow: 1;
  margin: 0;
  align-items: center;
  position: relative;
}

.ProgressProfile select {
  -webkit-appearance: none;
  appearance: none;
  font-size: 15px;
  padding: 9px 18px;
  border: 1px solid black;
  border-radius: 9px;
  margin-left: 9px;
  min-width: 90px;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='black'><path d='M5 8l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;  /* Adjust as needed */
  padding-right: 30px; /* Make room for the arrow */
}

.ProgressTabs {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 9px;
  margin: 0 27px;
}

.ProgressTab {
  display: flex;
  padding: 18px 36px;
  width: calc(100%/3);
  justify-content: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}

.CurrentProgressTab {
  text-decoration: underline;
  font-weight: 900;
}

.ProgressSettings {
  margin-top: 27px;
  display: flex;
  flex-direction: row;
}

.ProgressFilters {
  display: flex;
  flex-direction: row;
  margin: 0 30px;
  align-items: center;
  gap: 9px;
  flex-grow: 1;
}

.ProgressFilter {
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  border: 1px solid black;
  padding: 3px 12px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}

.CurrentProgressFilter {
  background-color: var(--button);
  color: var(--buttonText);
  font-weight: 900;
}

.ProgressSelector {
  display: flex;
  flex-direction: row;
  margin: 0 30px;
}

.ProgressSelector label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 9px;
  width: 54px;
  text-align: center;
}

.ProgressSelect {
  border-radius: 9px;
  border: 1px solid black;
  padding: 3px 18px;
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='black'><path d='M5 8l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;  /* Adjust as needed */
  padding-right: 36px; /* Make room for the arrow */
  width: 198px;
  text-align:center;
}

.ProgressSelectDropDown {
  border-radius: 9px;
  border: 1px solid black;
  padding: 9px 18px;
  margin-top: 33px;
  margin-left: 60px;
  background-color: #fff;
  width: 219px;
}

.ProgressSelectDropDown .DropDownContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 9px;
}

.ProgressSelectDropDown .DropDownContent select {
  -webkit-appearance: none;
  appearance: none;
  font-size: 15px;
  padding: 9px 18px;
  border: 1px solid black;
  border-radius: 9px;
  display: flex;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='black'><path d='M5 8l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;  /* Adjust as needed */
  padding-right: 30px; /* Make room for the arrow */
}

.ProgressSelectDropDown .DropDownSubmit button {
  font-weight: 800;
  padding: 9px 18px;
  border: none;
  background-color: var(--button);
  color: var(--buttonText);
  cursor: pointer;
  border-radius: 9px;
  border: 1px solid var(--button);
}

.ProgressSelectDropDown .DropDownButtons {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  gap: 9px;
  justify-content: flex-end;
}

.ProgressSelectDropDown .DropDownButtons .DropDownCancel button {
  background-color: white !important;
  color: black !important;
  border: 1px solid rgb(117, 117, 117) !important;
  border-radius: 9px;
  padding: 9px 18px;
  background-color: #fff;
  cursor: pointer;
}

.Progress {
  margin: 27px 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Progress .ProgressChart {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.Progress .ProgressChart .ProgressStacksList {
  display: flex;
  flex-direction: column;
  margin: 0px 15px;
  gap: 9px;
}

.Progress .ProgressChart .ProgressStacksListItem {
  padding: 18px;
  border-radius: 9px;
  border: 1px solid black;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.Progress .ProgressLoading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Progress .ProgressLoading span {
  font-weight: 900;
  margin-top: -27px;
  margin-bottom: 27px;
}

.Progress .chevron-right {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid black; /* Adjust the color as needed */
  display: inline-block;
  margin-left: 6px;
}