.StackShow {
  padding: 60px 18px 18px 18px;
  display: flex;
  flex-direction: row;
  z-index: 2;
  gap: 18px;
}

.StackShow #TermList {
  background-color: beige;
  z-index: 2;
  border-radius: 18px;
  padding: 18px;
  display: flex;
  width: 20%;
  position: fixed;
  height: calc(100% - 108px);
}

.StackShow #TermList ul {
  padding: 18px 9px 18px 27px;
  margin: 0 -18px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow: auto;
  width: 100%;
}

.StackShow #TermList ul::-webkit-scrollbar{width:5px;height:5px;}
.StackShow #TermList ul::-webkit-scrollbar-track{background:#f1f1f1; margin:18px 0;}
.StackShow #TermList ul::-webkit-scrollbar-thumb{background:#9ca6af}
.StackShow #TermList ul::-webkit-scrollbar-thumb:hover{background:#555}

.StackShow #TermList li {
  list-style: none;
  padding: 0;
  padding-right: 18px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.StackShow #TermList li .LoadMore {
  background-color: white;
  padding: 9px 36px;
  border: 1px solid black;
  border-radius: 9px;
  cursor: pointer;
}

.StackShow #TermList li .Small {
  font-size: 15px;
}

.StackShow #TermList li .Lock {
  color: black;
}

.StackShow #TermList li .Lock span {
  margin-right: 9px;
}

.SubscribeMoreTerms {
  background-color: var(--button);
  color: var(--buttonText);
  font-size: 18px;
  border-radius: 18px;
  padding: 18px 9px 18px 27px;
  margin-right: 18px;
  margin-top: -6px;
  cursor: pointer;
}

.SubscribeMoreTerms b {
  font-size: 21px;
}

.SuperWorkout {
  appearance: button;
  background-color: #fae04b;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  margin-bottom:9px;
}

.SuperWorkout:after {
  background-clip: padding-box;
  background-color: #e9cd32;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.SuperWorkout:main,
.SuperWorkout:focus {
  user-select: auto;
}

.SuperWorkout:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.SuperWorkout:disabled {
  cursor: auto;
}

.StackShow .Term {
  background-color: white;
  z-index: 2;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  width: calc(80% - 180px);
  padding: 36px;
  gap: 18px;
  margin-left: calc(20% + 63px);
  position: fixed;
  overflow-y: scroll;
  height: calc(100% - 144px);
}

.StackShow .Term::-webkit-scrollbar{width:5px;height:5px;}
.StackShow .Term::-webkit-scrollbar-track{background:#f1f1f1; margin:18px 0;}
.StackShow .Term::-webkit-scrollbar-thumb{background:#9ca6af}
.StackShow .Term::-webkit-scrollbar-thumb:hover{background:#555}

.StackShow .TermDetail .TermImages {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 396px;
  gap: 9px;
}

.StackShow .TermDetail .TermImage {
  display: 'flex';
}

.StackShow .TermDetail .TermImage img {
  width: 'auto';
  border-radius: 9px;
  max-height: 396px;
}

.StackShow .TermHeader {
  display: flex;
  flex-direction: row;
}

.StackShow .TermLang {
  display: flex;
  flex-direction: column;
}

/* TODO: move LanguageSelect to a more general css */
.LanguageSelect {
  position: relative;
  display: inline-block;
  width: 216px;
  height: 63px;
  background: #EAEAEA;
  overflow: hidden;
  border-radius: 9px;
  border: 1px solid #DCDCDC;
}

.LanguageSelect select {
  font-family: 'Montserrat';
  width: 100%;
  height: 100%;
  padding: 18px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #333;
  appearance: none;
  cursor: pointer;
  border-radius: 9px;
}

.LanguageSelect::after {
  content: "";
  background: url('../assets/down.png') no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 21px;
  right: 18px;
  width: 18px;
  height: 18px;
  pointer-events: none;
}

.StackShow .TermWord {
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-weight: 700;
  word-wrap: break-word;
  gap: 27px;
  flex-grow: 1;
}

.StackShow .TermWord span {
  /* font-family: "Libre Baskerville"; */
  /* font-family: "Lora"; */
  /* font-family: "Noto Serif"; */
  /* font-family: "Crimson Text"; */
  font-family: "Quicksand";
  /* font-family: "Mulish"; */
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 54px;
}

.StackShow .TermWord .Small {
  font-size: 27px;
  /* max-width: 540px; */
}

.StackShow .TermDetail {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.StackShow .TermDescription {
  font-size: 24px;
  line-height: 33px;
  /* display:inline-flex; */
  flex-wrap: wrap;
}

.StackShow .TermDescription p {
  /* font-family: "Libre Baskerville"; */
  /* font-family: "Lora"; */
  /* font-family: "Noto Serif"; */
  /* font-family: "Crimson Text"; */
  font-family: "Quicksand";
  /* font-family: "Mulish"; */
  display: flex;
  flex-wrap: wrap;
}

.StackShow .TermDescription span {
  cursor: pointer;
}

.StackShow .TermDescription .Giant {
  font-size: 45px;
  font-weight: 700;
  line-height: 63px;
}

.StackShow .TermDescription .Highlight {
  background-color: yellow;
  border-radius: 9px;
}

.StackShow .TermDescription span img {
  margin-right: 9px;
}

.dialog-bubble {
  padding-left: 72px;
  margin-right: 63px;
}

.bubble {
  padding: 9px 27px;
  margin: 9px;
  border-radius: 18px;
  position: relative;
}

.bubble p {
  align-items: center;
}

.bubble-left {
  background-color: #f0f0f0;
  float: left;
  clear: both;
  width: 100%;
}

.bubble-left::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #f0f0f0;
  left: -15px;
  top: 15px;
}

.bubble-left .avatar {
  left: -81px;
}

.bubble-right {
  background-color: beige;
  float: right;
  clear: both;
}

.bubble-right::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid beige;
  right: -15px;
  top: 15px;
}

.bubble-right .avatar {
  right: -81px;
}

.avatar {
  position: absolute;
  top: 0;
  width: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -15px;
  margin-right: -9px;
}

.avatar img {
  width: 54px;
  height: 54px;
  border-radius: 54%;
}

.Workout {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Workout img {
  width: 32px;
  height: auto;
  margin: 0 !important;
  border-radius: 0%;
}

.Workout span {
  font-size: 14px;
  margin-top: -3px;
}

.Choices {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0 18px;
}

.Choices .Choice {
  border-radius: 18px;
  border: 2px solid #333;
  box-shadow: 0 2px 0 #333;
  font-size: 18px;
  padding: 18px 36px;
  background-color: #fff;
  color: #333;
  font-weight: 700;
  cursor: pointer;
}

.Choices .Choice span {
  display: flex;
  flex-direction: row; 
  align-items: center;
}

.Choices .Selected {
  border-radius: 18px;
  border: 2px solid #333;
  box-shadow: 0 2px 0 #333;
  font-size: 18px;
  padding: 18px 36px;
  background-color: #fff;
  color: #333;
  font-weight: 700;
  margin-right: -54px;
  margin-left: 27px;
  cursor: pointer;
} 

.Choices .Selected span {
  display: flex;
  align-items: center;
}

.Choices .Submit {
  border-radius: 18px;
  border: 2px solid #51FFBF;
  box-shadow: 0 2px 0 #15C181;
  font-size: 18px;
  padding: 18px;
  background-color: #51FFBF;
  cursor: pointer;
  color: rgb(8, 34, 79);
  font-weight: 700;
}

.Choices .Submit:disabled,
.Choices .Submit[disabled]{
  border: 1px solid #999999;
  box-shadow: 0 2px 0 #999999;
  background-color: #cccccc;
  color: #666666;
}

.Congrats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Congrats p {
  display: flex;
  flex-direction: column;
  margin-left: 27px;
}

.Congrats h3 {
  margin: 0;
  margin-bottom:9px;
}

.Congrats img {
  border-radius: 54%;
  width: 27%;
}

.Speaker {
  display: flex;
  align-items: center;
}

.Earnings {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EarningsNotice {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EarningsNoticeImg {
  display: flex;
}

.Earnings img {
  width: 45px;
  height: 45px;
  margin-left: 3px;
}

.fraction {
  display: flex;
  align-items: center;
}

.fraction .fraction-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0 9px; */
}

.fraction span.fraction--numerator {
  border-bottom: 2px solid black;
  padding: 0 9px;
  margin: 0 6px;
}

.fraction span.word {
  margin: 0 3px;
}

.Giant .fraction span.word {
  margin: 0 6px;
}


@media only screen and (max-width: 768px), ( (max-width: 999px) and (orientation: landscape) ) {
  .StackShow {
    transform: scale(0.5);
    transform-origin: top left;
    width: calc(200% - 36px);
    height: 200%;
    position: absolute;
    margin-top: 27px;
  }
  .StackShow #TermList {
    height: calc(100% - 252px);
  }
  .StackShow .Term {
    width: calc(80% - 180px);
    height: calc(100% - 288px);
  }
}