.Profiles h2 {
  text-align: center;
}

.ProfileTabs {
  display: flex;
  flex-direction: row;
  gap: 36px;
  max-width: 396px;
  margin-top: 36px;
  justify-content: center;
}

.ProfileTab {
  cursor: pointer;
}

.ProfileTabName {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 9px;
}

.ProfileImageContainer {
  position: relative;
  width: 108px;
  height: 108px;
  object-fit: cover;
  border-radius: 50%;
}

.ProfileImageContainer img {
  border-radius: 54px;
}

.Close {
  float:right;
  margin-top: -6px;
  margin-bottom: 18px;
  margin-right: -6px;
}

.Close button {
  background: none !important;
  border: 0 !important;
  cursor: pointer;
}

.EditProfile h3 {
  margin-top: 0;
  text-decoration: underline;
}

.EditProfile h4 {
  margin: 9px 0;
}

.EditProfile button {
  font-weight: 800;
  padding: 9px 18px;
  border: none;
  background-color: var(--button);
  color: var(--buttonText);
  cursor: pointer;
  border-radius: 9px;
  border: 1px solid var(--button);
}

.EditProfile input {
  -webkit-appearance: none;
  padding: 9px 18px;
  font-size: 15px;
  border-radius: 9px;
  background-color: white;
  border: 1px solid black;
  width: 297px;
  
}

.EditProfile .Confirm {
  display: flex;
  gap: 9px;
  justify-content: flex-end;
}

.EditProfileBody {
  display: flex;
  flex-direction: row;
  gap: 18px;
  /* margin-bottom: 36px; */
}

.AvatarTab {
  display: flex;
  flex-direction: column;
}

.InputTab {
  margin: 12px 0;
}

.CancelButton {
  background-color: white !important;
  color: black !important;
  border: 1px solid rgb(117, 117, 117) !important;
}

.CurrentAvatar {
  margin-right: 18px;
}

.CurrentAvatar img {
  width: 135px;
  height: 135px;
  border-radius: 54%;
  margin-bottom: 9px;
  justify-content: center;
}

.DeleteProfile {
  display: flex;
  flex-direction: row;
}

.DeleteProfile .Description {
  width: 360px;
  margin-right: 18px;
  font-size:14px;
}

.DeleteProfile button {
  width: 162px;
  background-color: white;
  border: 1px solid red;
  color: red;
}

.EditIconOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent white overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color:rgb(0, 0, 0);
  font-weight: bold;
}

.EditIconOverlay span {
  display:inline-block;
  margin-top: 1px;
  margin-left: 3px;
}

.EditIconOverlay img {
  border-radius: 0;
}

.AvatarSelected {
  border-radius: 54%;
  border: 6px solid var(--button) !important;
}

.Avatar {
  cursor: pointer;
  width: 135px;
  height: 135px;
  border: 6px solid beige;
}

.Avatar img {
  border-radius: 54%;
  width: 135px;
  height: 135px;
}

.ChangeAvatar {
  width: 540px;
}

#AvatarsBody {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  max-height: 450px;
  flex-wrap: wrap;
  overflow-y: auto;
}

#AvatarsBody .Avatar {
  margin: 9px; 
}