#PaymentBody {
  overflow-y: auto;
  height: calc(100vh - 45px);
  width: calc(100%);
  position: absolute;
  top: 45px;
  z-index: 2;
}

.PaymentContent {
  background-color: #fafaf9;
  border-radius: 9px;
  border: 1px solid black;
  margin: 18px auto;
  display: flex;
  flex-direction: column;
  max-width: 1044px;
}

.PaymentContent .CancelSubscription {
  padding: 45px;
}

.PaymentContent .CancelSubscription .RefundAmount {
  font-size: 18px;
  font-weight: bold;
  margin-top: 9px;
}

.PaymentContent .CancelSubscription .Title {
  text-transform: capitalize;
  text-decoration: underline;
  font-weight: 900;
  font-size: 27px;
  margin-bottom: 18px;
}

.PaymentContent .AddPaymentForm {
  padding: 27px;
}

.PaymentForm {
  padding: 36px;
}

.PaymentCompleted {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.PaymentCompleted .PaymentNotice {
  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
  padding-top: 18px;
  text-align: center;
}

.PaymentCompleted .GetStarted {
  width: 59.4%;
  margin-bottom: 36px;
}

.PaymentCompleted .GetStarted button {
  appearance: button;
  background-color: #f32703;
  border: solid transparent;
  border-radius: 9px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.72px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 9px 27px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  margin-top: 18px;
}

.PaymentCompleted .GetStarted button:after {
  background-clip: padding-box;
  background-color: #ff4e2f;
  border: solid transparent;
  border-radius: 9px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.PaymentCompleted img {
  width: 25%;
  border-radius: 9px;
  margin-top:36px;
}

.PaymentNotice {
  margin-top: 9px;
}