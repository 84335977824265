#BadgesBody {
  overflow-y: auto;
  height: calc(100vh - 45px);
  width: calc(100%);
  position: absolute;
  top: 45px;
  z-index: 2;
}

.Badges {
  background-color: white;
  border-radius: 9px;
  padding: 9px 36px;
  border: 1px solid black;
  margin: 18px;
  display: flex;
  flex-direction: row;
  gap: 36px;
}

.Badges h3 {
  padding-bottom: 9px;
  border-bottom: 1px solid #333;
  margin-bottom: 36px;
}

.MyStuff {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.MyDiamonds {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  align-items: center;
  height: 99px;
}

.MyDiamonds img {
  border-radius: 54%;
}

.MyDiamonds .Amount {
  display: flex;
  flex-direction: row;
  font-size: 81px;
  margin-left: 18px;
  font-weight: 700;
  /* color: #FFD700; */
}

.MyBadges {
  margin-bottom: 36px;
  display: flex;
  gap: 27px;
  flex-wrap: wrap;
}

.BadgesStore {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.BadgesCatalog {
  display: flex;
  flex-wrap: wrap;
  gap: 27px;
  margin-bottom: 18px;
}

.BadgetItem {
  cursor: pointer;
}

.BadgetItem img {
  border-radius: 54%;
  width: 135px;
}

.BadgesFetchMore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 18px 0;
}

.BadgesFetchMore button {
  background-color: white;
  padding: 9px 36px;
  border: 1px solid black;
  border-radius: 9px;
  cursor: pointer;
}

.BadgesBuy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: -18px;
}

.BadgeAmount {
  font-size: 27px;
  font-weight: bold;
}

.BadgesBuy img {
  width: 45px;
}

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.ModalContent {
  background-color: white;
  padding: 27px 54px;
  border-radius: 18px;
  z-index: 999;
  width: 45vw;
  height: 45vh;
  max-width: 540px;
  max-height: 450px;
}

.ModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CloseButton {
  border: none;
  background: transparent;
  cursor: pointer;
}

.CloseButton img {
  width: 25px;
  height: 25px;
}

.ModalBuy {
  display: flex;
  flex-direction: row;
  padding: 36px 0;
  gap: 45px;
}

.ModalBuy img {
  border-radius: 54%;
}

.ModalContent .CloseModal {
  appearance: button;
  -webkit-user-select: none;
  background-color: #dadada;
  color: black;
  border: 1px solid #000;
  border-radius: 9px;
  padding: 9px 18px;
  cursor: pointer;
}

.ModalBuy .Point {
  font-size: 15px;
  line-height: 21px;
}

.ModalBuy .Point h3 {
  margin: 0;
  font-size: 15px;
}

.ModalImage {
  width: 50%;
}

.ModalBuyContent {
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin-top: -9px;
  width: 50%;
}

.ModalCost {
  display: flex;
  align-items: center;
}

.ModalCost img {
  width: 99px;
}

.ModalCost span {
  font-size: 63px;
  font-weight: bold;
}

.ModalBuyButton {
  display: flex;
  justify-content: center;
}

.ModalBuyButton span {
  font-size: 12px;
}

.DisabledBuyButton {
  padding: 18px 18px !important;
  font-size: 18px !important;
}

.ModalBuyButton button {
  background-color: white;
  font-size: 27px;
  font-weight: bold;
  padding: 18px 72px;
  border: 1px solid black;
  box-shadow: 0 2px 0 black;
  border-radius: 9px;
  cursor: pointer;
  color: black;
}

.ModalBuyButton button:disabled,
.ModalBuyButton button[disabled]{
  border: 1px solid #999999;
  box-shadow: 0 2px 0 #999999;
  background-color: #cccccc;
  color: #666666;
}