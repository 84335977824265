#SubscribeBody {
  overflow-y: auto;
  height: calc(100vh - 45px);
  width: calc(100%);
  position: absolute;
  top: 45px;
  z-index: 2;
}

.SubscribeContent {
  background-color: white;
  border-radius: 9px;
  border: 1px solid black;
  margin: 18px auto;
  display: flex;
  flex-direction: column;
  max-width: 1044px;
}

.SubscribeContent .Title {
  margin: 27px 18px 9px 18px;
  text-transform: capitalize;
  text-decoration: underline;
  font-weight: 900;
  font-size: 27px;
}

.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 36px;
}

.Loading span {
  font-weight: bold;
}

.MainPlans {
  display: flex;
  flex-direction: row;
  gap: 18px;
  margin: 18px;
}

.Policies {
  border-radius: 9px;
  border: 1px solid black;
  padding: 21px 27px;
  margin: 0 18px;
}

.Policies .Refund {
  margin-bottom: 6px;
}

.Plan {
  display: flex;
  flex-direction: column;
  width: calc(50% - 9px);
  align-items: center;
}

.PlanContent {
  border-radius: 9px;
  border: 1px solid black;
  padding: 27px;
  width: calc(100% - 54px);
  min-height: 207px;
}

.PlanContent .PlanName {
  font-size: 24px;
  font-weight: 900;
  background: -webkit-linear-gradient(to top right, #00B284, #c9ffeb); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to top right, #00B284, #c9ffeb); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to top right, #00B284, #c9ffeb); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to top right, #00B284, #c9ffeb); /* Standard syntax (must be last) */
  
  /* Apply the background image to the text */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;

  /* Make the text transparent so the background shows through */
  color: transparent;
}

.PlanContent .PlanSubheader {
  color: gray;
}

.PlanContent .PlanPrice {
  font-size: 27px;
  font-weight: 900;
  margin: 9px 0;
}

.PlanContent .PlanPoint {
  margin-bottom: 3px;
}

.PlanContent .PlanPoint .Checkmark {
  color:#00B284 !important;
  margin-right: 3px;
}

.PlanContent .PlanButton {
  appearance: button;
  background-color: #f32703;
  border: solid transparent;
  border-radius: 9px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.72px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 9px 27px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  margin-top: 18px;
}

.PlanContent .PlanButton:after {
  background-clip: padding-box;
  background-color: #ff4e2f;
  border: solid transparent;
  border-radius: 9px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.PlanContent .PlanButton:disabled {
  background-color: #b5b5b5;  /* A gray background color */
  cursor: not-allowed;  /* Change cursor to not allowed */
  opacity: 0.7;  /* Reduce the opacity for a "grayed-out" look */
}

.PlanContent .PlanButton:disabled:after {
  background-color: #c6c6c6;  /* A slightly different gray for the :after element */
  border-bottom-color: #a0a0a0;  /* Gray border at the bottom */
}

.PlanContent .UpgradeDescription {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  gap: 18px;
  margin: 18px;
}

.PlanContent .UpgradeTab {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.PlanContent .UpgradeTab .ActiveProfiles {
  border: 1px solid black;
  border-radius: 9px;
  padding: 18px;
  margin-bottom: -27px;
  margin-top: -9px;
}

.PlanContent .UpgradeDescription .UpgradeTab label {
  display: flex;
  flex-direction: column;
  text-decoration: underline;
}

.PlanContent .UpgradeDescription .UpgradeTab span {
  display: flex;
  flex-direction: column;
  font-size: 16.2px;
}

.PlanContent .UpgradeDescription .UpgradeTab .SpecialNotice {
  background-color: var(--button);
  font-size: 15px;
  padding: 9px 18px;
  border: 1px solid black;
  border-radius: 9px;
  font-weight: 700;
}

.PlanContent .UpgradeDescription .UpgradeTab .PaymentMethods {
  margin-top: -9px;
}

.PlanContent .UpgradeDescription .UpgradeTab .PaymentMethods h4 {
  margin: 9px 0;
}

.PlanContent .DowngradeDescription {
  padding: 9px;
}

.PlanContent .DowngradeDescription .DowngradeNoticeList {
  display: flex;
  flex-direction: column;
  margin-bottom: -9px;
}

.PlanContent .DowngradeDescription .DowngradeNoticeList .DowngradeSpecialNotice {
  background-color: var(--button);
  font-size: 15px;
  padding: 9px 18px;
  border: 1px solid black;
  border-radius: 9px;
  font-weight: 700;
}

.PlanContent .DowngradeDescription .DowngradeNoticeList .DowngradeNotice {
  margin: 9px 18px;
  font-size: 15px;
}

.PlanContent .DowngradeDescription .CurrentProfiles {
  display: flex;
  flex-direction: column;
  padding: 18px;
  gap: 9px;
  margin-top: 18px;
  border: 1px solid black;
  border-radius: 9px;
}

.PlanContent .DowngradeDescription .CurrentProfile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PlanContent .DowngradeDescription .CurrentProfile label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 63px;
}

.PlanContent .DowngradeDescription .CurrentProfile button {
  user-select: none;
  -webkit-user-select: none;
  padding: 3px 18px;
  /* font-size: 15px; */
  background-color: white;
  border: 1px solid black;
  border-radius: 9px;
  color: black;
  cursor: pointer;
  margin-top: 1px;
}

.PlanContent .DowngradeDescription .CurrentProfile .CurrentStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 18px;
  font-size: 15px;
}

.MorePlans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;
  margin: 18px;
}

.MorePlansButton {
  margin: 3px;
}

.MorePlansButton button {
  user-select: none;
  -webkit-user-select: none;
  font-size: 15px;
  background-color: white;
  border: 0;
  color: black;
  text-decoration: underline;
  margin-left: -3px;
  cursor: pointer;
}