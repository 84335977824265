:root {
  --button: #51FFBF;
  --buttonText: rgb(8, 34, 79);
}

::-webkit-scrollbar{width:5px;height:5px;}
::-webkit-scrollbar-track{background:#f1f1f1;}
::-webkit-scrollbar-thumb{background:#9ca6af}
::-webkit-scrollbar-thumb:hover{background:#555}

.App {
  background-image: url("../../public/bg.png");
  /* background-image: url("https://media.discordapp.net/attachments/1104964135122370632/1108793189042770011/speedant_landing_page_for_online_children_encyclopedia_forest_A_0aa2f8c6-6b04-4be7-a56e-55be0ce8adf4.png"); */
  /* background-image: url("https://media.discordapp.net/attachments/1104964135122370632/1108794908044709988/speedant_landing_page_for_online_children_encyclopedia_forest_C_87472771-e995-419e-a677-163acc924a9c.png"); */
  /* background-image: url("https://media.discordapp.net/attachments/1104964135122370632/1108562522379915314/speedant_landing_page_for_online_children_encyclopedia_forest_e_1d5fc57c-0dd2-48e6-bcb5-f7a02a7e0dbc.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}


.AppBg {
  min-height: 100vh;
  min-width: 100%;
  opacity: 0.54;
  position: fixed;
  background-color: white;
  background-size: cover;
  z-index: 1;
}

.Action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: beige;
  padding: 63px;
  border-radius: 18px;
  max-width: 522px;
  z-index: 2;
}

.Action h1 {
  font-size: 33px;
}

.Action p {
  width: 486px;
  font-size: 17.1px;
  color:rgb(97, 97, 97);
  line-height: 24px;
  margin: 36px 0;
}

.Action .Notice {
  font-size: 18px;
  margin-bottom: 18px;
}

#EmailGetStartedError {
  margin-top: 9px !important;
  margin-bottom: 0 !important;
}

.TextInputError {
  color: #cc0000;
  margin-top: -3px;
  margin-bottom: 9px;
  margin-left: 18px;
}

.input-container {
  position: relative;
  width: 468px;
  margin-left: -9px;
  margin-top: 45px;
}

#search-input {
  font-size: 15px;
  width: 100%;
  height: 39px;
  padding: 9px 0px 9px 27px;  /* Make room for the button */
  border-radius: 36px;
  border: 1px solid #000;
}

#submit-button {
  font-weight: 800;
  position: absolute;
  right: 0;
  top: 0;
  padding: 18px 36px;
  border: none;
  background-color: var(--button);
  color: var(--buttonText);
  cursor: pointer;
  border-radius: 36px;
  margin-top: 4px;
  margin-right: -26px;
}

#submit-button:hover {
  background-color: var(--button);
}

.Close {
  float:right;
  margin-top: -18px;
  margin-bottom: 27px;
  margin-right: -6px;
}

.Close button {
  background: none;
  border: 0;
  cursor: pointer;
}

.AcceptTerms {
  font-size: 12px;
  margin-top: 18px;
  padding: 0 3px;
  width: 396px;
}

.LoginFooter {
  font-size: 15px;
  margin-top: 18px;
  padding: 0 3px;
}

.Footer {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color:rgb(0, 0, 0);
  opacity: 0.8;
}

.Footer li {
  float: left;
}

.Footer li a {
  font-size: 12px;
  display: block;
  padding: 9px 18px;
  color:white;
  text-decoration:none;
  text-align:center;
}

.Bold {
  font-weight: 700;
}

.ActionButton {
  appearance: button;
  background-color: #f32703;
  border: solid transparent;
  border-radius: 9px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.72px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 9px 27px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  margin-top: 18px;
}

.ActionButton:after {
  background-clip: padding-box;
  background-color: #ff4e2f;
  border: solid transparent;
  border-radius: 9px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.ActionButton:disabled {
  background-color: #b5b5b5;  /* A gray background color */
  cursor: not-allowed;  /* Change cursor to not allowed */
  opacity: 0.7;  /* Reduce the opacity for a "grayed-out" look */
}

.ActionButton:disabled:after {
  background-color: #c6c6c6;  /* A slightly different gray for the :after element */
  border-bottom-color: #a0a0a0;  /* Gray border at the bottom */
}

@media only screen and (max-width: 768px), ( (max-width: 999px) and (orientation: landscape) ) {
  .App {
    background-image: url("../../public/bg.png");
    height: 100vh;
    overflow: auto;
  }
  .Action {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    background-color: beige;
    padding: 18px;
    max-width: calc(100% - 54px);
    margin: 81px auto;
    border-radius: 18px;
    z-index: 2;
  }
  .Action p {
    width: 100%;
    font-size: 17.1px;
    margin: 0;
    margin-bottom: 27px;
    color:rgb(97, 97, 97);
    line-height: 24px;
  }
  .Action h1 {
    font-size: 33px;
  }
  .input-container {
    width: 100%;
    position: relative;
    margin-left: -9px;
    margin-top: 27px;
    margin-bottom: 27px;
  }
  #search-input {
    width: calc(100% - 10px);
  }
  #submit-button{
    margin-right: -15px;
  }
  .AcceptTerms {
    width: 100%;
  }
  .Footer {
    display: none;
  }
}