/* TODO: 
change to infinite scroll
e.g. https://coderpad.io/blog/development/how-to-implement-infinite-scroll-in-react-js/ 
*/

#StacksBody {
  overflow-y: auto;
  height: calc(100vh - 99px);
  /* position: absolute;
  top: 99px; */
  z-index: 2;
  width: 100%;
}

.FilterBy {
  display: flex;
  flex-direction: row;
  padding: 18px;
  z-index: 2;
  margin-top: 45px;
}

.FilterButtons {
  display: flex;
  gap: 18px;
  flex-grow: 1;
}

.FilterButtons button {
  padding: 9px 18px;
  border: 0;
  color: black;
  background-color: white;
  cursor: pointer;
  z-index: 2;
  border: 1px solid black;
  border-radius: 18px;
  font-size: 15px;
}

.SubscribeButton {
  display: flex;
}

.SubscribeButton button {
  z-index: 2;

  appearance: button;
  background-color: #f32703;
  border: solid transparent;
  border-radius: 18px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.72px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 9px 27px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.SubscribeButton button:after {
  background-clip: padding-box;
  background-color: #ff4e2f;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.SortByActiveBtn {
  background-color: var(--button) !important;
  font-weight: 900;
}

.StacksContent {
  display: flex;
  flex-direction: row;
}

.StacksCategories {
  display: flex;
  flex-direction: column;
  margin: 0 6px 36px 18px;
  padding: 27px;
  background-color: beige;
  border-radius: 18px;
  gap: 18px;
  min-width: 225px;
  z-index: 9;
  height: calc(100vh - 189px);
  overflow-y: scroll;
}

.StacksCategories span {
  cursor: pointer;
  font-size: 18px;
}

.StacksCategories::-webkit-scrollbar{width:5px;height:5px;}
.StacksCategories::-webkit-scrollbar-track{background:#f1f1f1; margin:18px 0;}
.StacksCategories::-webkit-scrollbar-thumb{background:#9ca6af}
.StacksCategories::-webkit-scrollbar-thumb:hover{background:#555}

.categoryHeader {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
}

.Stacks {
  padding: 0px 6px 45px 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  align-items: center;
  justify-content: center;
}

.NoFavorites {
  padding: 18px 27px;
  background-color: white;
  font-weight: bold;
  border-radius: 18px;
  margin: 0 12px;
}

.Stack {
  display: block;
  cursor: pointer;
  position: relative;
  /* display: flex;
  flex-direction: column; */
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.Stack .Favorite {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
}

.Stack .Favorite img {
  width: 24px;
  height: 24px;
}

.Stack .FavoriteNotice {
  position: absolute;
  top: 42px;
  right: 9px;
  background-color: white;
  padding: 9px 18px;
  border-radius: 18px;
  font-weight: 700;
}

.StackImage {
  width: 100%;
  height: 100%;
}

.StackImage img {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  z-index: 2;
}

.StackName {
  padding: 9px 18px;
  background-color: beige;
  color:black;
  border-radius: 9px;
  width: calc(100% - 36px);
  text-align: center;
  font-weight:700;
}

@media only screen and (min-width: 243px) {
  .Stack {
    width: calc(100% - 18px);
    height: calc(100% - 18px);
  }
  .StackName {
    font-size: 18px;
  }
}

@media only screen and (min-width: 540px) {
  .Stack {
    width: calc(33% - 18px);
    height: calc(33% - 18px);
  }
  .StackName {
    font-size: 15px;
  }
}

@media only screen and (min-width: 765px) {
  .Stack {
    width: calc(25% - 18px);
    height: calc(25% - 18px);
  }
  .StackName {
    font-size: 15px;
  }
  .StackNameSmall {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1233px) {
  .Stack {
    width: calc(25% - 18px);
    height: calc(25% - 18px);
  }
  .StackName {
    font-size: 18px;
  }
  .StackNameSmall {
    font-size: 15px;
  }
}

/* EXTREMELY IMPORTANT STYLE */
.StackModal {
  z-index: 99;
  position: absolute;
  width: 100%;
}

#MobileNotice {
  display: none;
}

@media only screen and (max-width: 768px), ( (max-width: 999px) and (orientation: landscape) ) {
  #MobileNotice {
    display: block;
    position: relative;
    z-index: 2;
    margin-bottom: 18px;
    margin-left: 18px;
    margin-right: 18px;
    padding: 18px;
    background-color: white;
    border-radius: 9px;
    font-size: 15px;
  }
}