body {
  font-family: 'Montserrat';
  margin: 0;
  overflow: hidden;
}

a, a:visited {
  font-family: 'Montserrat';
  color:#7272AB;
  text-decoration: none;
}

button {
  font-family: 'Montserrat';
}

#root {
  overflow: hidden;
}